
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
// import { list, hasUse, del } from "../../api/model.js";
import { client } from '@/client';
import { defineComponent } from 'vue';
import { ElMessage } from "element-plus";
export default defineComponent({
  
  name: "model-index",
  setup() {
    return {
      Edit,
      Delete,
      View,
      Search,
    };
  },
  data: () => {
    return {
      multipleSelection: [] as any[],
      tableData: [] as any[],
      count: 0,
      cur: 1,
      loading: true,
    };
  },
  created() {
    this.list();
  },
  methods: {
    //查询
    async list() {
      try {
        let res = await client.callApi("model/List",{cur:this.cur});
        if (res.isSucc) {
          this.tableData = [...res.res.list];
          this.count = res.res.count;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    toggleSelection(rows: any[]) {
        let multipleTable:any = this.$refs.multipleTable;
      if (rows) {
        rows.forEach((row) => {
          multipleTable.toggleRowSelection(row);
        });
      } else {
        multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val: any[]) {
      this.multipleSelection = val;
    },

    //翻页
    handleCurrentChange(e: any) {
      this.cur = Number(e);
      sessionStorage.setItem("cur",this.cur.toString());
      this.list();
    },

    async hasUse(id: number) {
      let ret = await client.callApi("model/HasUse",{id:id});
      if (ret.isSucc) {
        return ret.res.count;
      } else {
        return 1;
      }
    },

    //跳转到编辑
    toEdit(e: { id: any; }) {
      let id = e.id;
      this.$router.push({ name: "model-edit", params: { id: id } });
    },

    // 查看字段
    handleClick(e: { id: any; }) {
      console.log("e--->", e);
      // const { id } = e;
      // this.$router.push({ name: "field-index", query: { mid: id } });
    },

    //删除文章
    async handleDel(e: { id: any; table_name: any; }) {
      const { id, table_name } = e;
      try {
        let res = await this.hasUse(id);
        if (res == 0) {
          let ret = await client.callApi("model/Del",{id:id});
          if (ret.isSucc) {
            ElMessage({
              message: "删除成功 ^_^",
              type: "error",
            });
            this.list();
          }
        } else {
          ElMessage({
            message: "当前模型已经使用，不能删除！",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

});
